.centered-Col {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.middle-Line {
    flex: 1;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.navbar {}

.navbar .navlink {
    border-bottom: 2px solid #4096ff00;
    transition: all 0.2s ease;
    padding: 10px 0;
    font-weight: 500;
}

.navbar .navlink.active {
    border-bottom: 2px solid #4096ff;

    color: #4096ff;
}